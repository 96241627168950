export class Model {
  public pageLoading: boolean = true;
  public planCode: string = "";
  public saveButtonActive: boolean = true;
  public saveButtonLoading: boolean = false;
  public saveButtonCompleted: boolean = false;
  public name: InputText = new InputText(50);
  public explanation: InputText = new InputText(1000);
  public price: InputPrice = new InputPrice();
}
export class InputText {
  public constructor(maxLength: number, minLength = 0) {
    this.maxLength = maxLength;
    this.minLength = minLength;
  }
  public value: string = "";
  public maxLength: number = 0;
  public minLength: number = 0;
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
}
export class InputPrice {
  public value: string = "";
  public maxLength: number = 6;
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
  public focus: boolean = false;
  public dummy: Array<string> = [" ", " ", " ", " ", " ", " ", " "];
}
export class Error {
  public constructor(message: string, index: number | null = null) {
    this.message = message;
    this.index = index;
  }
  public message: string;
  public index: number | null;
}
